html,
body {
  height: 100%;
  background-color: var(--gray-light);
  -webkit-tap-highlight-color: transparent;
}

$tabs-toggle-link-hover-background-color: transparent;
// $tabs-toggle-link-hover-border-color: none;
// $box-background-color: #3abcff;

@import "../node_modules/bulma/bulma.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/components/dropdown.sass";
@import "../node_modules/bulma/sass/components/tabs.sass";
@import "../node_modules/bulma/sass/elements/button.sass";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
